export default function lazyLoadImages(delay = 0) {
	setTimeout(() => {
		const targets = document.querySelectorAll('.lazy');
		// eslint-disable-next-line no-shadow
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (entry.target.hasAttribute('data-class')) {
						entry.target.classList.add(entry.target.getAttribute('data-class'));
					}

					if (entry.target.hasAttribute('data-srcset')) {
						entry.target.setAttribute('srcset', entry.target.getAttribute('data-srcset'));
					}

					if (entry.target.hasAttribute('data-src')) {
						entry.target.setAttribute('src', entry.target.getAttribute('data-src'));
					}

					if (entry.target.hasAttribute('data-href')) {
						entry.target.setAttribute('href', entry.target.getAttribute('data-href'));
					}

					entry.target.removeAttribute('data-srcset');
					entry.target.removeAttribute('data-src');
					entry.target.removeAttribute('data-href');
					entry.target.removeAttribute('data-class');
					entry.target.classList.remove('lazy');

					observer.unobserve(entry.target);
				}
			});
		});

		targets.forEach((target) => observer.observe(target));
	}, delay);
}
