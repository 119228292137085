import getFormServiceApiDomain from '../url/getFormServiceApiDomain';

/**
 * Hits the API to get the branded display name used for consent capture
 * @param {String} vendorPublicIdentifier - can be found in QMP > Client Details (for consent purposes, should only be displaying / accepting one client value)
 * @param {String} zip - zipcode
 * @param {Boolean} shouldUpdateConsentHiddenField - Flag to update HomePhoneConsentLanguage hidden field.
 */
export default function renderBrandedConsent(vendorPublicIdentifier, zip = null, shouldUpdateConsentHiddenField = false) {
	const brandedTargetDiv = document.querySelector('[data-branded-consent]'),
		apiDomain = getFormServiceApiDomain(),
		request = new XMLHttpRequest();
	let apiUrl = `${apiDomain}utils/vendor-display-name?vendorKeys=${vendorPublicIdentifier}`;

	if (zip) {
		apiUrl += `&zip=${zip}`;
	}

	let response = {};
	request.open('GET', apiUrl);
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200 && brandedTargetDiv) {
				brandedTargetDiv.innerHTML = response.data[vendorPublicIdentifier];

				if (window.modForm && typeof window.modForm.updateConsentHiddenFields === 'function' && shouldUpdateConsentHiddenField) {
					window.modForm.updateConsentHiddenFields();
				}
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});

}
